import _ from "lodash";
import { writable, derived } from "svelte/store";
import * as yup from "yup";
import { serializeBusinessProjection } from "./Serialization";
import { kebabCase } from "utils/to_kebab_case";

export const specifiedTypes = { No: 0, Yes: 1 };
export const ownerTypes = { Taxpayer: "taxpayer", Spouse: "spouse" };
export const businessTypes = {
  "S Corporation": "s-corporation",
  "C Corporation": "c-corporation",
  Partnership: "partnership",
  "Sole Proprietorship": "sole-proprietorship"
};
export const stateOptions = {
  Alaska: "AK",
  Alabama: "AL",
  Arkansas: "AR",
  "American Samoa": "AS",
  Arizona: "AZ",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  "District of Columbia": "DC",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Iowa: "IA",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Massachusetts: "MA",
  Maryland: "MD",
  Maine: "ME",
  Michigan: "MI",
  Minnesota: "MN",
  Missouri: "MO",
  "Northern Mariana Islands": "MP",
  Mississippi: "MS",
  Montana: "MT",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Nebraska: "NE",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  Nevada: "NV",
  "New York": "NY",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Virginia: "VA",
  "Virgin Islands": "VI",
  Vermont: "VT",
  Washington: "WA",
  Wisconsin: "WI",
  "West Virginia": "WV",
  Wyoming: "WY"
};

let schema = yup.object().shape({
  id: yup.string().required(),
  companyName: yup.string().required("Company Name is required."),
  businessType: yup
    .string()
    .oneOf(_.values(businessTypes))
    .when("owner", {
      is: "spouse",
      then: (schema) =>
        schema.notOneOf(
          ["sole-proprietorship"],
          "Sorry, Tax Planner Pro doesn’t support this business configuration just yet."
        ), // when owner is spouse, reject sole proprietorship
      otherwise: (schema) => schema.required("Business Type is required.")
    }),
  businessSharePercent: yup
    .number()
    .min(0, "Min value 0%.")
    .max(100, "Max value 100%.")
    .nullable()
    .transform(emptyStringToNull),
  owner: yup.string().oneOf(_.values(ownerTypes)).required("Owner is required."),
  businessStart: yup
    .string()
    .matches(/^([01]?\d)\/([0123]?\d)\/\d\d\d\d$/, "The date must be in US format.")
    .nullable(),
  payroll: yup.number().min(0, "Enter not negative value please.").nullable().transform(emptyStringToNull),
  fixedAssets: yup
    .number()
    .min(0, "Enter not negative value please.")
    .nullable()
    .transform(emptyStringToNull),
  isSpecified: yup.number().oneOf([0, 1]).required("Specified Service Business is required."),
  rentalRealEstate: yup.bool().required("Rental Real Estate is required."),
  businessState: yup.string().oneOf(_.values(stateOptions)).required("Business State is required."),
  incomeEntries: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      projection: yup.number().required(),
      federalOverride: yup.number().nullable().transform(emptyStringToNull),
      stateOverride: yup.number().nullable().transform(emptyStringToNull)
    })
  ),
  expenseEntries: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      projection: yup.number().required(),
      federalOverride: yup.number().nullable().transform(emptyStringToNull),
      stateOverride: yup.number().nullable().transform(emptyStringToNull)
    })
  ),
  totalIncome: yup.object().shape({
    actual: yup.number().nullable(),
    projection: yup.number().nullable(),
    federalFinalProjection: yup.number().nullable(),
    stateFinalProjection: yup.number().nullable()
  }),
  totalExpenses: yup.object().shape({
    actual: yup.number().nullable(),
    projection: yup.number().nullable(),
    federalFinalProjection: yup.number().nullable(),
    stateFinalProjection: yup.number().nullable()
  }),
  allowableDeductions: yup.object().shape({
    actual: yup.number().nullable(),
    projection: yup.number().nullable(),
    federalFinalProjection: yup.number().nullable(),
    stateFinalProjection: yup.number().nullable()
  }),
  netIncome: yup.object().shape({
    actual: yup.number().nullable(),
    projection: yup.number().nullable(),
    federalFinalProjection: yup.number().nullable(),
    stateFinalProjection: yup.number().nullable()
  }),
  taxableIncome: yup.object().shape({
    actual: yup.number().nullable(),
    projection: yup.number().nullable(),
    federalFinalProjection: yup.number().nullable(),
    stateFinalProjection: yup.number().nullable()
  })
});

export const businessProjection = writable({
  incomeEntries: [],
  expenseEntries: [],
  totalIncome: {},
  totalExpenses: {},
  allowableDeductions: {},
  netIncome: {},
  taxableIncome: {}
});

export const businessProjectionValidation = derived(businessProjection, ($bp) => {
  try {
    schema.validateSync($bp, { abortEarly: false });
    return new Map();
  } catch (err) {
    return new Map(err.inner.map((err) => [kebabCase(err.path), err.errors[0]]));
  }
});

export const businessProjectionSerialized = derived(businessProjection, ($bp) => {
  try {
    schema.validateSync($bp, { abortEarly: true });
    return serializeBusinessProjection($bp);
  } catch {
    return null;
  }
});

export const businessProjectionVisitors = writable(new Set([]));

export const visitField = (field) =>
  businessProjectionVisitors.update((visitors) => new Set([...visitors, field]));

// helper for yup empty string for number transform function
function emptyStringToNull(value, originalValue) {
  return String(originalValue).trim() === "" ? null : value;
}
